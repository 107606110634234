import { useState } from 'react';
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { styled } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Container,
  Typography,
  Stack,
  Card,
  Collapse,
  CardMedia,
  CardContent,
  IconButton,
  CardActions,
  Link
} from '@material-ui/core';
import { Chain2Icon } from 'src/network2label/networkLabel';

// ----------------------------------------------------------------------

const CardWrapper = styled('div')(({ background }) => ({
  width: '100%',
  height: '100%',
  // transition: '0.5s',
  cursor: 'pointer',
  background: '#1d1e20',
  backgroundSize: '100%',
  backgroundPosition: 'center center',
  "&:hover": {
    backgroundImage: `url(${background})`,
    backgroundSize: '100%',
    backgroundPosition: 'center center',
  }
}))

const DisabledCover = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: 'rgb(255, 255, 255, 0.1)',
  zIndex: 99,
  cursor: 'not-allowed'
}))
// ----------------------------------------------------------------------

export default function PublicLowItem({ id, tokenLogo, projectName, disabled = false, cryptoStakings, lpFarms, tokenSymbol, telegram, twitter, website, chain }) {

  return (
    <Card
      sx={{
        position: 'relative',
        height: 70,
        borderRadius: '6px',
        border: !disabled ? '1px solid #F0B90B' : '1px solid #555555',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
      }}
    >
      {disabled ? <DisabledCover /> : <></>}
      <RouterLink to={`/project/${id}`} style={{ width: '100%', textDecoration: 'none', decorationColor: '#ffffff', color: '#ffffff' }}>
        <CardWrapper>
          <CardContent>
            <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack direction="row" sx={{ width: '20%' }} justifyContent="center">
                <Box
                  component="img"
                  src={`data:image/png;base64, ${tokenLogo}`}
                  alt="logo"
                  sx={{ mx: "auto", width: 40, height: 40, borderRadius: 50, border: '2px solid #F0B90B' }}
                />
              </Stack>
              <Stack direction="row" sx={{ width: '10%' }} justifyContent="center">
                <Box
                  component="img"
                  src={Chain2Icon[chain]}
                  alt="logo"
                  sx={{ mx: "auto", width: 40, height: 40 }}
                />
              </Stack>
              <Stack direction="column" sx={{ width: '10%' }} alignItems="center">
                {
                  lpFarms > 0 ? <Typography variant='h5' lineHeight={1}>LP Farm</Typography> : <></>
                }
                {
                  cryptoStakings > 0 ? <Typography variant='h5' lineHeight={1}>Staking</Typography> : <></>
                }
              </Stack>
              <Stack direction="row" sx={{ width: '20%' }} justifyContent="center">
                <Typography variant='h4' sx={{ fontSize: 25 }}>
                  {tokenSymbol}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ width: '10%' }} justifyContent="center">
                <Typography variant='h4' sx={{ fontSize: 25 }}>
                  {100}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ width: '10%' }} justifyContent="center">
                <Typography variant='h4' sx={{ fontSize: 25 }}>
                  {100}
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ width: '20%' }} justifyContent="center">
                <Typography variant='h4' sx={{ fontSize: 25 }}>
                  {projectName}
                </Typography>
              </Stack>
            </Stack>
          </CardContent>
        </CardWrapper>
      </RouterLink>
    </Card>
  );
}
