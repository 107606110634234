import { useState } from 'react';
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { styled } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Container,
  Typography,
  Stack,
  Card,
  Collapse,
  CardMedia,
  CardContent,
  IconButton,
  CardActions,
  Link
} from '@material-ui/core';
import { Chain2Icon } from 'src/network2label/networkLabel';

// ----------------------------------------------------------------------

const CardWrapper = styled('div')(({ background }) => ({
  width: '100%',
  height: '100%',
  // transition: '0.5s',
  background: '#1d1e20',
  backgroundSize: '100%',
  backgroundPosition: 'center center',
  "&:hover": {
    backgroundImage: `url(${background})`,
    backgroundSize: '100%',
    backgroundPosition: 'center center',
  }
}))
// ----------------------------------------------------------------------

export default function PublicLowItemHeader() {

  return (
    <Card
      sx={{
        position: 'relative',
        height: 70,
        borderRadius: '6px',
        border: '1px solid #F0B90B',
        // boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
      }}
    >
      <CardWrapper>
        <CardContent>
          <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Stack direction="row" sx={{ width: '20%' }} justifyContent="center">
              <Typography variant='h4' sx={{ fontSize: 25 }}>
                Token
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ width: '10%' }} justifyContent="center">
              <Typography variant='h4' sx={{ fontSize: 25 }}>
                Network
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ width: '10%' }} justifyContent="center">
              <Typography variant='h4' sx={{ fontSize: 25 }}>
                Type
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ width: '20%' }} justifyContent="center">
              <Typography variant='h4' sx={{ fontSize: 25 }}>
                Symbol
              </Typography>
            </Stack>
            <Stack direction="row" sx={{ width: '10%' }} justifyContent="center">
              <Typography variant='h4' sx={{ fontSize: 25 }}>
                TVL
              </Typography>
             </Stack>
            <Stack direction="row" sx={{ width: '10%' }} justifyContent="center">
             <Typography variant='h4' sx={{ fontSize: 25 }}>
                APR
              </Typography>
             </Stack>
            <Stack direction="row" sx={{ width: '20%' }} justifyContent="center">
              <Typography variant='h4' sx={{ fontSize: 25 }}>
                Project Name
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </CardWrapper>
    </Card>
  );
}