//react
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
// material
import { Container, Typography, Stack, } from '@material-ui/core'
import { startToast } from 'src/components/Toast';
import { useGlobalContext } from 'src/contexts/GlobalContext';
//
import { useWeb3React } from '@web3-react/core';
//
import { FiCheck } from 'react-icons/fi';
import CheckBox from 'react-custom-checkbox';
//
import CardButton from 'src/components/CardButton';
import Input from 'src/components/Input';
import Switch from 'src/components/Switch';
import NetworkSelect from 'src/components/NetworkSelect';
//
import { deploy, getContract, getTokenInfo } from 'src/utils/ContractHelper';
import isEmpty from 'src/utils/isEmpty';
import trim from 'src/utils/trim';
import checkEthereumAddress from 'src/utils/isEthereumAddress';
//
import StakeLockUp from 'src/contracts/StakeLockUp.json';
import StakeNoLockUp from 'src/contracts/StakeNoLockUp.json';
import LPFarm from 'src/contracts/LPFarm.json';
//
import { Address, StakeTypes } from 'src/constants';
//
import { addCryptoStakingToProject, addLpFarmToProject, addSubFarmForLPFarm, getLPFarmByContractAddress } from 'src/actions/firebase';

export default function AdminForm() {

  const [projectType, setProjectType] = useState(1);
  const [stakeType, setStakeType] = useState(1);
  const [deployNew, setDeployNew] = useState(true);
  const [isInitialize, setIsInitialize] = useState(false);

  const [isReflection, setIsReflection] = useState(1);
  const [stakeToken, setStakeToken] = useState("");
  const [rewardToken, setRewardToken] = useState("");
  const [dividendToken, setDividendToken] = useState("");
  const [dexRouter, setDexRouter] = useState("");
  const [stakingContract, setStakingContract] = useState("");
  const [lpToken, setLPToken] = useState("");
  const [earnedStakePath, setEarnedStakePath] = useState([]);
  const [reflectionStakePath, setReflectionStakePath] = useState([]);
  const [rewardPerBlock, setRewardPerBlock] = useState(0);
  const [depositFee, setDepositFee] = useState(0);
  const [withdrawFee, setWithdrawFee] = useState(0);

  const [actionLoading, setActionLoading] = useState(false);

  const [rewardTokenInfo, setRewardTokenInfo] = useState(null);
  const [stakeTokenInfo, setStakeTokenInfo] = useState(null);
  const [dividendTokenInfo, setDividendTokenInfo] = useState(null);

  const { state } = useGlobalContext();
  const { library, account } = useWeb3React();
  const { id, STAKE_TYPE } = useParams();
  const { zeroAddress } = Address;

  /*conditions*/
  const onlyImportCard = !deployNew && !isInitialize;
  const onlyInitialize = !deployNew && isInitialize;

  const startWithStaking = async () => {
    if (stakeType == 1) {
      if (deployNew) {
        createLockUpAndDeployNew();
      } else if (onlyInitialize) {
        createLockUp();
      } else if (onlyImportCard) {
        onlyImportLockUp();
      }
    } else if (stakeType == 2) {
      if (deployNew) {
        createNoLockUp();
      } else if (onlyInitialize) {
        initializeNoLockUp();
      } else if (onlyImportCard) {
        onlyImportNoLockUp();
      }
    }
  }

  const createLockUp = async () => {
    if (!account) {
      startToast("warning", "Please connect your wallet.");
      return false;
    }

    if (isEmpty([stakeToken, rewardToken, dividendToken, dexRouter, stakeToken != rewardToken ? earnedStakePath : true, dividendToken != stakeToken ? reflectionStakePath : true, stakingContract])) {
      startToast("error", "Please fill all fields with valid value.");
      console.log(stakeToken != rewardToken)
      return false;
    }

    if (!checkEthereumAddress([stakeToken, rewardToken, dividendToken, dexRouter, stakingContract])) {
      startToast("error", "Please input valid Ethereum address.");
      return false;
    }

    try {
      const _stakeTokenInfo = await getTokenInfo(stakeToken, library?.getSigner());
      const _rewardTokenInfo = await getTokenInfo(rewardToken, library?.getSigner());
      const _dividendTokenInfo = await getTokenInfo(dividendToken, library?.getSigner());

      setStakeTokenInfo(_stakeTokenInfo);
      setRewardTokenInfo(_rewardTokenInfo);
      setDividendTokenInfo(_dividendTokenInfo);
    } catch (e) {
      startToast("warning", "Can't get token information.");
      console.log(e);
      return false;
    }

    try {
      setActionLoading(true);
      const contract = await getContract(StakeLockUp.abi, StakeLockUp.deployedBytecode, stakingContract, library?.getSigner());
      var tx = await contract.initialize(stakeToken, rewardToken, dividendToken, dexRouter, rewardToken != stakeToken ? earnedStakePath : [rewardToken, stakeToken], dividendToken != stakeToken ? reflectionStakePath : [dividendToken, stakeToken]);
      await tx.wait();

      const dbResult = await addCryptoStakingToProject(id, {
        stakeType: StakeTypes.LOCK_UP,
        stakeToken: stakeToken,
        rewardToken: rewardToken,
        dividendToken: dividendToken,
        dexRouter: dexRouter,
        earnedStakePath: earnedStakePath,
        reflectionStakePath: reflectionStakePath,
        contractAddress: stakingContract,
        owner: account,
        chainId: state.selectedChain,
        stakeTokenInfo: stakeTokenInfo,
        rewardTokenInfo: rewardTokenInfo,
        dividendTokenInfo: dividendTokenInfo
      })

      if (dbResult) {
        startToast("success", "Crypto Staking successfully created.");
        setActionLoading(false);
      } else {
        startToast("error", "Something was wrong.");
        setActionLoading(false);
      }
    } catch (e) {
      startToast("error", "Transaction did not successed.");
      console.log(e);
      setActionLoading(false);
      return false;
    }
  }

  const createLockUpAndDeployNew = async () => {
    if (!account) {
      startToast("warning", "Please connect your wallet.");
      return false;
    }
    if (isEmpty([stakeToken, rewardToken, dividendToken, dexRouter, stakeToken != rewardToken ? earnedStakePath : true, dividendToken != stakeToken ? reflectionStakePath : true])) {
      startToast("error", "Please fill all fields with valid value.");
      return false;
    }

    if (!checkEthereumAddress([stakeToken, rewardToken, dividendToken, dexRouter])) {
      startToast("error", "Please input valid Ethereum address.");
      return false;
    }

    const _stakeTokenInfo = await getTokenInfo(stakeToken, library?.getSigner());
    const _rewardTokenInfo = await getTokenInfo(rewardToken, library?.getSigner());
    const _dividendTokenInfo = await getTokenInfo(dividendToken, library?.getSigner());

    setStakeTokenInfo(_stakeTokenInfo);
    setRewardTokenInfo(_rewardTokenInfo);
    setDividendTokenInfo(_dividendTokenInfo);

    try {
      setActionLoading(true);
      const address = await deploy(StakeLockUp.abi, StakeLockUp.bytecode, library?.getSigner(), []);
      const contract = await getContract(StakeLockUp.abi, StakeLockUp.deployedBytecode, address, library?.getSigner());
      var tx = await contract.initialize(stakeToken, rewardToken, dividendToken, dexRouter, rewardToken != stakeToken ? earnedStakePath : [rewardToken, stakeToken], dividendToken != stakeToken ? reflectionStakePath : [dividendToken, stakeToken]);
      await tx.wait();

      const dbResult = await addCryptoStakingToProject(id, {
        stakeType: StakeTypes.LOCK_UP,
        stakeToken: stakeToken,
        rewardToken: rewardToken,
        dividendToken: dividendToken,
        dexRouter: dexRouter,
        earnedStakePath: earnedStakePath,
        reflectionStakePath: reflectionStakePath,
        contractAddress: address,
        owner: account,
        chainId: state.selectedChain,
        stakeTokenInfo: _stakeTokenInfo,
        rewardTokenInfo: _rewardTokenInfo,
        dividendTokenInfo: _dividendTokenInfo,
        subStakings: []
      })
      if (dbResult) {
        startToast("success", "Crypto Staking successfully created.");
        setActionLoading(false);
      } else {
        startToast("error", "Something was wrong.");
        setActionLoading(false);
      }

    } catch (e) {
      startToast("error", "Transaction did not successed.");
      console.log(e);
      setActionLoading(false);
      return false;
    }
  }

  const createNoLockUp = async () => {

    if (!account) {
      startToast("warning", "Please connect your wallet.");
      return false;
    }

    if (isEmpty([stakeToken, rewardToken, isReflection == 1 ? dividendToken : zeroAddress, rewardPerBlock, dexRouter, stakeToken != rewardToken ? earnedStakePath : true, dividendToken != stakeToken ? reflectionStakePath : true])) {
      startToast("error", "Please fill all fields with valid value.");
      return false;
    }

    if (!checkEthereumAddress([stakeToken, rewardToken, isReflection == 1 ? dividendToken : zeroAddress, dexRouter])) {
      startToast("error", "Please input valid Ethereum address.");
      return false;
    }

    const _stakeTokenInfo = await getTokenInfo(stakeToken, library?.getSigner());
    const _rewardTokenInfo = await getTokenInfo(rewardToken, library?.getSigner());
    const _dividendTokenInfo = await getTokenInfo(dividendToken, library?.getSigner());

    setStakeTokenInfo(_stakeTokenInfo);
    setRewardTokenInfo(_rewardTokenInfo);
    setDividendTokenInfo(_dividendTokenInfo);

    try {
      setActionLoading(true);
      const address = await deploy(StakeNoLockUp.abi, StakeNoLockUp.bytecode, library?.getSigner(), []);
      const contract = await getContract(StakeNoLockUp.abi, StakeNoLockUp.deployedBytecode, address, library?.getSigner());
      var tx = await contract.initialize(stakeToken, rewardToken, isReflection == 1 ? dividendToken : zeroAddress, rewardPerBlock, depositFee, withdrawFee, dexRouter, rewardToken != stakeToken ? earnedStakePath : [rewardToken, stakeToken], dividendToken != stakeToken ? reflectionStakePath : [dividendToken, stakeToken], isReflection == 1 ? true : false);
      await tx.wait();

      const dbResult = await addCryptoStakingToProject(id, {
        stakeType: StakeTypes.NO_LOCK_UP,
        stakeToken: stakeToken,
        rewardToken: rewardToken,
        dividendToken: dividendToken,
        dexRouter: dexRouter,
        earnedStakePath: earnedStakePath,
        reflectionStakePath: reflectionStakePath,
        contractAddress: address,
        rewardPerBlock: rewardPerBlock,
        hasDividend: isReflection == 1 ? true : false,
        chainId: state.selectedChain,
        stakeTokenInfo: _stakeTokenInfo,
        rewardTokenInfo: _rewardTokenInfo,
        dividendTokenInfo: _dividendTokenInfo
      })
      if (dbResult) {
        startToast("success", "Crypto Staking successfully created.");
        setActionLoading(false);
      } else {
        startToast("error", "Something was wrong.");
        setActionLoading(false);
      }

    } catch (e) {
      startToast("error", "Transaction did not successed.");
      setActionLoading(false);
      return false;
    }
  }

  const initializeNoLockUp = async () => {

    if (!account) {
      startToast("warning", "Please connect your wallet.");
      return false;
    }

    if (isEmpty([stakeToken, rewardToken, isReflection == 1 ? dividendToken : zeroAddress, rewardPerBlock, depositFee, withdrawFee, dexRouter, stakeToken != rewardToken ? earnedStakePath : true, dividendToken != stakeToken ? reflectionStakePath : true, stakingContract])) {
      startToast("error", "Please fill all fields with valid value.");
      return false;
    }

    if (!checkEthereumAddress([stakeToken, rewardToken, isReflection == 1 ? dividendToken : zeroAddress, dexRouter, stakingContract])) {
      startToast("error", "Please input valid Ethereum address.");
      return false;
    }

    const _stakeTokenInfo = await getTokenInfo(stakeToken, library?.getSigner());
    const _rewardTokenInfo = await getTokenInfo(rewardToken, library?.getSigner());
    const _dividendTokenInfo = await getTokenInfo(dividendToken, library?.getSigner());

    setStakeTokenInfo(_stakeTokenInfo);
    setRewardTokenInfo(_rewardTokenInfo);
    setDividendTokenInfo(_dividendTokenInfo);

    try {
      setActionLoading(true);
      const contract = await getContract(StakeNoLockUp.abi, StakeNoLockUp.deployedBytecode, stakingContract, library?.getSigner());
      var tx = await contract.initialize(stakeToken, rewardToken, isReflection == 1 ? dividendToken : zeroAddress, rewardPerBlock, depositFee, withdrawFee, dexRouter, rewardToken != stakeToken ? earnedStakePath : [rewardToken, stakeToken], dividendToken != stakeToken ? reflectionStakePath : [dividendToken, stakeToken], isReflection == 1 ? true : false);
      await tx.wait();

      const dbResult = await addCryptoStakingToProject(id, {
        stakeType: StakeTypes.NO_LOCK_UP,
        stakeToken: stakeToken,
        rewardToken: rewardToken,
        dividendToken: dividendToken,
        dexRouter: dexRouter,
        earnedStakePath: earnedStakePath,
        reflectionStakePath: reflectionStakePath,
        contractAddress: stakingContract,
        rewardPerBlock: rewardPerBlock,
        hasDividend: isReflection == 1 ? true : false,
        chainId: state.selectedChain,
        stakeTokenInfo: _stakeTokenInfo,
        rewardTokenInfo: _rewardTokenInfo,
        dividendTokenInfo: _dividendTokenInfo
      })
      if (dbResult) {
        startToast("success", "Crypto Staking successfully created.");
        setActionLoading(false);
      } else {
        startToast("error", "Something was wrong.");
        setActionLoading(false);
      }

    } catch (e) {
      startToast("error", "Transaction did not successed.");
      setActionLoading(false);
      return false;
    }
  }

  const createLPFarmAndDeployNew = async () => {
    if (!account) {
      startToast("warning", "Please connect your wallet.");
      return false;
    }

    if (isEmpty([rewardToken, isReflection == 1 ? dividendToken : zeroAddress, rewardPerBlock])) {
      startToast("error", "Please fill all fields with valid value.");
      return false;
    }

    if (!checkEthereumAddress([rewardToken, isReflection == 1 ? dividendToken : zeroAddress])) {
      startToast("error", "Please input valid Ethereum address.");
      return false;
    }

    const _rewardTokenInfo = await getTokenInfo(rewardToken, library?.getSigner());
    let _dividendTokenInfo = null;
    if (isReflection === 1) {
      _dividendTokenInfo = await getTokenInfo(dividendToken, library?.getSigner());
    }

    setRewardTokenInfo(_rewardTokenInfo);
    setDividendTokenInfo(_dividendTokenInfo);

    try {
      setActionLoading(true);
      const address = await deploy(LPFarm.abi, LPFarm.bytecode, library?.getSigner(), [rewardToken, isReflection == 1 ? dividendToken : zeroAddress, rewardPerBlock, isReflection == 1 ? true : false]);
      const contract = await getContract(LPFarm.abi, LPFarm.deployedBytecode, address, library?.getSigner());

      // const tx = await contract.add(allocPoint, lpToken, depositFee, withdrawFee, true);
      // await tx.wait();

      contract.on('SubFarmCreated', async (pid) => {
        const masterFarmDBResult = await addLpFarmToProject(id, {
          stakeType: isReflection == 1 ? StakeTypes.REFLECTION : StakeTypes.NO_REFLECTION,
          rewardToken: rewardToken,
          dividendToken: dividendToken,
          rewardPerBlock: rewardPerBlock,
          contractAddress: address,
          hasDividend: isReflection == 1 ? true : false,
          owner: account,
          chainId: state.selectedChain,
          subFarms: [
            // {
            //   id: pid.toNumber(),
            //   allocPoint: allocPoint,
            //   lpToken: lpToken,
            //   depositFee: depositFee,
            //   withdrawFee: withdrawFee,
            //   withUpdate: true
            // }
          ],
          rewardTokenInfo: _rewardTokenInfo,
          dividendTokenInfo: _dividendTokenInfo
        })
        if (masterFarmDBResult) {
          startToast("success", "LP Farm successfully created.")
          setActionLoading(false);
        } else {
          startToast("error", "Something was wrong.")
          setActionLoading(false);
        }
      })

    } catch (e) {
      console.log(e);
      startToast("error", "Transaction did not successed.");
      setActionLoading(false);
      return false;
    }
  }

  const onlyImportLockUp = async () => {
    if (!account) {
      startToast("warning", "Please connect your wallet.");
      return false;
    }

    if (isEmpty([stakingContract])) {
      startToast("error", "Please fill all fields with valid value.");
      return false;
    }

    if (!checkEthereumAddress([stakingContract])) {
      startToast("error", "Please input valid Ethereum address.");
      return false;
    }

    try {
      setActionLoading(true);
      const contract = await getContract(StakeLockUp.abi, StakeLockUp.deployedBytecode, stakingContract, library?.getSigner());
      const isInitialized = await contract.isInitialized();
      if (!isInitialized) {
        setActionLoading(false);
        startToast("error", "This contract is not initialized yet.");
        return false;
      }

      const stakeToken = await contract.stakingToken();
      const rewardToken = await contract.earnedToken();
      const dividendToken = await contract.dividendToken();
      const dexRouter = await contract.uniRouterAddress();
      const reflectionToStakedPath = await contract.getReflectionToStakedPath();
      const earnedToStakedPath = await contract.getEarnedToStakedPath();

      try {
        const _stakeTokenInfo = await getTokenInfo(stakeToken, library?.getSigner());
        const _rewardTokenInfo = await getTokenInfo(rewardToken, library?.getSigner());
        const _dividendTokenInfo = await getTokenInfo(dividendToken, library?.getSigner());

        setStakeTokenInfo(_stakeTokenInfo);
        setRewardTokenInfo(_rewardTokenInfo);
        setDividendTokenInfo(_dividendTokenInfo);
      } catch (e) {
        setActionLoading(false);
        startToast("warning", "Can't get token information.");
        console.log(e);
        return false;
      }

      const dbResult = await addCryptoStakingToProject(id, {
        stakeType: StakeTypes.LOCK_UP,
        stakeToken: stakeToken,
        rewardToken: rewardToken,
        dividendToken: dividendToken,
        dexRouter: dexRouter,
        earnedStakePath: earnedToStakedPath,
        reflectionStakePath: reflectionToStakedPath,
        contractAddress: stakingContract,
        owner: account,
        chainId: state.selectedChain,
        stakeTokenInfo: stakeTokenInfo,
        rewardTokenInfo: rewardTokenInfo,
        dividendTokenInfo: dividendTokenInfo
      })

      if (dbResult) {
        setActionLoading(false);
        startToast("success", "Successfully imported.");
      } else {
        setActionLoading(false);
        startToast("error", "Something was wrong.");
        return false;
      }

      console.log(stakeToken, rewardToken, dividendToken, dexRouter, reflectionToStakedPath, earnedToStakedPath);
    } catch (err) {
      setActionLoading(false);
      startToast("error", "Can't get the contract data.");
      console.log(err);
    }
  }

  const onlyImportNoLockUp = async () => {
    if (!account) {
      startToast("warning", "Please connect your wallet.");
      return false;
    }

    if (isEmpty([stakingContract])) {
      startToast("error", "Please fill all fields with valid value.");
      return false;
    }

    if (!checkEthereumAddress([stakingContract])) {
      startToast("error", "Please input valid Ethereum address.");
      return false;
    }

    try {
      setActionLoading(true);
      const contract = await getContract(StakeNoLockUp.abi, StakeNoLockUp.deployedBytecode, stakingContract, library?.getSigner());
      const isInitialized = await contract.isInitialized();
      if (!isInitialized) {
        setActionLoading(false);
        startToast("error", "This contract is not initialized yet.");
        return false;
      }

      const stakeToken = await contract.stakingToken();
      const rewardToken = await contract.earnedToken();
      const dividendToken = await contract.dividendToken();
      const rewardPerBlock = await contract.rewardPerBlock();
      const depositFee = await contract.depositFee();
      const withdrawFee = await contract.withdrawFee();
      const dexRouter = await contract.uniRouterAddress();
      const reflectionToStakedPath = await contract.getReflectionToStakedPath();
      const earnedToStakedPath = await contract.getEarnedToStakedPath();
      const hasDividend = await contract.hasDividend();

      console.log(stakeToken, rewardToken, dividendToken, rewardPerBlock, depositFee, withdrawFee, dexRouter, reflectionStakePath, earnedToStakedPath);

      try {
        const _stakeTokenInfo = await getTokenInfo(stakeToken, library?.getSigner());
        const _rewardTokenInfo = await getTokenInfo(rewardToken, library?.getSigner());
        const _dividendTokenInfo = await getTokenInfo(dividendToken, library?.getSigner());

        setStakeTokenInfo(_stakeTokenInfo);
        setRewardTokenInfo(_rewardTokenInfo);
        setDividendTokenInfo(_dividendTokenInfo);
      } catch (e) {
        setActionLoading(false);
        startToast("warning", "Can't get token information.");
        console.log(e);
        return false;
      }

      const dbResult = await addCryptoStakingToProject(id, {
        stakeType: StakeTypes.NO_LOCK_UP,
        stakeToken: stakeToken,
        rewardToken: rewardToken,
        dividendToken: dividendToken,
        dexRouter: dexRouter,
        earnedStakePath: earnedToStakedPath,
        reflectionStakePath: reflectionToStakedPath,
        contractAddress: stakingContract,
        rewardPerBlock: rewardPerBlock.toNumber(),
        hasDividend: hasDividend,
        chainId: state.selectedChain,
        stakeTokenInfo: stakeTokenInfo,
        rewardTokenInfo: rewardTokenInfo,
        dividendTokenInfo: dividendTokenInfo
      })

      if (dbResult) {
        setActionLoading(false);
        startToast("success", "Successfully imported.");
      } else {
        setActionLoading(false);
        startToast("error", "Something was wrong.");
        return false;
      }
    } catch (err) {
      setActionLoading(false);
      startToast("error", "Can't get the contract data.");
      console.log(err);
    }
  }

  useEffect(() => {
    switch (STAKE_TYPE) {
      case StakeTypes.CS:
        setProjectType(1);
        break;
      case StakeTypes.LP:
        setProjectType(2);
        break;
      default:
        break;
    }
  }, [])

  return (
    <>
      <Container maxWidth="lg">
        <Container
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            borderRadius: 2,
            border: '2px solid rgba(255, 255, 255, 0.4)',
            marginTop: 20,
          }}
          direction="column"
        >
          <Typography
            sx={{
              mb: 4,
              lineHeight: 1,
              fontSize: 70,
              fontWeight: 700,
            }}
          >
            Manage Stakestation
          </Typography>
          {/* Start Add new Card */}

          <Container spacing={8} sx={{ p: 5, width: 500, height: 'fit-content', border: '1px solid #F0B90B', boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px', borderRadius: 1, marginTop: 5 }}>
            <Typography variant='h4'>Create Staking and Farming</Typography>

            <Stack
              direction="row"
              spacing={7}
              paddingTop={2}
              sx={{ justifyContent: 'space-between' }}
            >
              <Switch label="Project:" labelStyle={{ fontSize: 20 }} onChange={(e) => setProjectType(e.target.value)} options={[{ value: 1, label: 'Staking' }, { value: 2, label: 'LP Farming' }]} value={projectType} disabled={true} />
              {
                projectType == 1 ? <Switch label="Type:" labelStyle={{ fontSize: 20 }} onChange={(e) => setStakeType(e.target.value)} options={[{ value: 1, label: 'Lockup' }, { value: 2, label: 'Non-Lockup' }]} value={projectType == 2 ? 2 : stakeType} /> :
                  <Switch label="Reflection:" labelStyle={{ fontSize: 20 }} onChange={(e) => setIsReflection(e.target.value)} options={[{ value: 1, label: 'Reflection' }, { value: 0, label: 'No-Reflection' }]} value={projectType == 1 ? 1 : isReflection} />
              }
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={2} sx={{ justifyContent: 'space-between' }}>
              <Input placeholder="0x...." label="Stake Token" labelStyle={{ fontSize: 20 }} value={stakeToken} onChange={(e) => setStakeToken(e.target.value)} disabled={projectType == 2 || onlyImportCard} />
              <Input placeholder="0x...." label="Reward Token" labelStyle={{ fontSize: 20 }} value={rewardToken} onChange={(e) => setRewardToken(e.target.value)} disabled={onlyImportCard} />
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={2} sx={{ justifyContent: 'space-between' }}>
              <Input placeholder="0x...." label="Dividend Token" labelStyle={{ fontSize: 20 }} value={isReflection == 1 ? dividendToken : zeroAddress} onChange={e => setDividendToken(e.target.value)} disabled={(projectType == 2 && isReflection == 1) || onlyImportCard} />
              <Input placeholder="___%" label="Reward per Block" labelStyle={{ fontSize: 20 }} value={rewardPerBlock} onChange={e => setRewardPerBlock(e.target.value)} disabled={stakeType == 1 && (projectType == 1 ? true : false) || onlyImportCard} />
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={2}>
              <Input placeholder="___%" label="Deposit Fee" labelStyle={{ fontSize: 20 }} value={depositFee} onChange={e => setDepositFee(e.target.value)} disabled={(stakeType == 1 && projectType == 1) || (projectType == 2 ? true : false) || onlyImportCard} />
              <Input placeholder="___%" label="Withdraw Fee" labelStyle={{ fontSize: 20 }} value={withdrawFee} onChange={e => setWithdrawFee(e.target.value)} disabled={(stakeType == 1 && projectType) == 1 || (projectType == 2 ? true : false) || onlyImportCard} />
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={2} sx={{ justifyContent: 'space-between' }}>
              <Input
                placeholder="0x...."
                label="Dex Router"
                labelStyle={{ fontSize: 20 }}
                value={dexRouter}
                onChange={e => setDexRouter(e.target.value)}
                disabled={projectType == 2 || onlyImportCard}
              />
              <Input placeholder="0x...." label="Staking Contract" labelStyle={{ fontSize: 20 }} value={stakingContract} onChange={e => setStakingContract(e.target.value)} disabled={!onlyInitialize && !onlyImportCard} />
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={2} sx={{ justifyContent: 'space-between' }}>
              <Input placeholder="0x...." label="LP Token" labelStyle={{ fontSize: 20 }} disabled={projectType == 1 || onlyImportCard} value={lpToken} onChange={e => setLPToken(e.target.value)} />
              <NetworkSelect label="Deploy Network" wrapperStyle={{ width: '45%' }} labelStyle={{ fontSize: 20 }} />
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={2}>
              <Input placeholder="0x...." label="Earned, Stake Path" labelStyle={{ fontSize: 20 }} value={earnedStakePath.toString()} onChange={e => setEarnedStakePath(trim(e.target.value.split(',')))} disabled={projectType == 2 || onlyImportCard} />
              <Input placeholder="0x...." label="Reflection, Stake Path" labelStyle={{ fontSize: 20 }} value={reflectionStakePath.toString()} onChange={e => setReflectionStakePath(trim(e.target.value.split(',')))} disabled={projectType == 2 || onlyImportCard} />
            </Stack>

            <Stack direction="row" spacing={7} paddingTop={2} sx={{ justifyContent: 'space-between' }}>
              <Stack direction="column" justifyContent="center">
                <CheckBox
                  checked={deployNew || projectType == 2}
                  onChange={(e) => { setDeployNew(e) }}
                  icon={<FiCheck />}
                  label="Deploy New and Create"
                  borderColor="#F0B90B"
                  style={{ cursor: 'pointer', marginLeft: 20 }}
                  labelStyle={{ fontSize: 20, marginLeft: 10 }}
                  disabled={projectType == 2}
                />
                {
                  !deployNew ?
                    <CheckBox
                      checked={isInitialize && !deployNew}
                      onChange={(e) => { setIsInitialize(e) }}
                      icon={<FiCheck />}
                      label="Only Initialize"
                      borderColor="#F0B90B"
                      style={{ cursor: 'pointer', marginLeft: 20 }}
                      labelStyle={{ fontSize: 20, marginLeft: 10 }}
                      disabled={deployNew}
                    /> : <></>
                }
              </Stack>
              <CardButton
                style={{ width: '45%', fontSize: 25 }}
                onClick={projectType == 1 ? startWithStaking : createLPFarmAndDeployNew}
                loading={actionLoading}
              >
                {
                  deployNew ? 'Create New' : (isInitialize ? 'Initialize' : 'Import Card')
                }
              </CardButton>
            </Stack>
          </Container>
        </Container>
      </Container>
    </>
  )
}