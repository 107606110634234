import { GET_AUTH } from 'src/actions/type';
  
  const initialState = {
    auth: {}
  };
  
  function authReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_AUTH:
        console.log(type);
        return {
          ...state,
          auth: payload,
        };
      default:
        return state;
    }
  }
  
  export default authReducer;