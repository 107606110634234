export const truncateAddress = (_address) => {
    if (!_address) return "No Account";
    const address = String(_address);
    return `${address?.slice(0, 4)}...${address?.slice(address.length - 4, address.length - 1)}`
};

export const toHex = (num) => {
    const val = Number(num);
    return "0x" + val.toString(16);
};

export const safeBalance = (num, decimal) => {
    return (parseInt(num, 16) / 10 ** decimal).toFixed(3);
}